.notification {
    background-color: #ffcc00; /* Orange background */
    color: #333; /* Dark text color */
    padding: 10px 20px; /* Padding */
    border-radius: 5px; /* Rounded corners */
    display: flex; /* Flexbox for alignment */
    justify-content: space-between; /* Space between text and button */
    align-items: center; /* Center vertically */
    position: fixed; /* Fixed position */
    top: 20px; /* Distance from the top */
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Adjust for centering */
    z-index: 1000; /* Ensure it appears above other content */
}

.close-button {
    background: none; /* No background */
    border: none; /* No border */
    color: #333; /* Dark text color */
    cursor: pointer; /* Pointer cursor */
    font-size: 16px; /* Font size */
    margin-left: 15px; /* Space between text and button */
}
