/* General Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
}

/* Main Container */
.main-container {
  background-color: #2E0E46;
  color: #FFFFFF;
  text-align: center;
  padding: 20px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

/* Logo */
.logo {
  position: absolute;
  top: 30px;
  left: 30px;
  width: 100px;
}

/* Background Decorations */
.background-decorations {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
}

.role-pm {
  position: absolute;
  width: 475px;
  height: 475px;
  top: -300px;
  left: -418px;
  background: #7775DD;
  border-radius: 50%;
  opacity: 0.2;
  filter: blur(150px);
}

.role-designer {
  position: absolute;
  width: 737px;
  height: 737px;
  top: -599px;
  left: -320px;
  background: #FFB703;
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}

.role-qa {
  position: absolute;
  width: 400px;
  height: 400px;
  top: -262px;
  left: 88px;
  background: #CD75DD;
}

.role-dev {
  position: absolute;
  width: 704px;
  height: 704px;
  top: 376px;
  left: 396px;
  background: #3BAFD2;
  transform: rotate(-177.82deg);
}

/* Robot Icon */
.robot-icon {
  margin-top: 30px;
  width: 150px;
  animation: float 2s ease-in-out infinite;
}

@keyframes float {
  0% {
      transform: translateY(0px);
  }
  50% {
      transform: translateY(-10px);
  }
  100% {
      transform: translateY(0px);
  }
}

/* Description Text */
.description-text {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.3;
  color: #FFFFFF;
  max-width: 600px;
  letter-spacing: -0.015em;
  margin-top: 20px;
  text-align: center;
  padding: 0 10px;
}

/* How It Works Section */
.how-it-works {
  color: #CBC9CE;
  font-size: 24px;
  font-weight: 600;
  margin-top: 40px;
  position: relative;
  display: inline-block;
  text-align: center;
}

.how-it-works .underline {
  width: 100%;
  height: 2px;
  background-color: #CBC9CE;
  margin-top: 5px;
}

/* Grid Container */
.grid-container {
  display: flex;
  gap: 20px;
  margin-top: 40px;
  flex-wrap: wrap;
  justify-content: center;
}

.grid-item {
  background: radial-gradient(circle at top left, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), rgba(255, 255, 255, 0.6);
  border: 2px solid #FFFFFF;
  box-shadow: inset -1.75px -1.75px 87.5px rgba(255, 255, 255, 0.02), inset 1.75px 1.75px 1.4px rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(7.35px);
  padding: 20px;
  border-radius: 18px;
  width: 300px;
  max-width: 90%;
  color: #2E0E46;
  text-align: left;
}

/* Step Label */
.step-label {
  font-size: 14px;
  font-weight: 600;
  color: #7541D5;
  letter-spacing: 0.03em;
  display: block;
  margin-bottom: 5px;
}

/* Title */
.title {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.3;
  color: #2E0E46;
  margin: 5px 0;
  letter-spacing: -0.015em;
}

/* Grid Description */
.grid-description {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #2E0E46;
  margin-top: 10px;
}

/* Get Started Button */
.get-started-btn {
  margin-top: 40px;
  background-color: #7541D5;
  color: #FFFFFF;
  padding: 12px 24px;
  font-size: 18px;
  font-weight: 600;
  border: none;
  border-radius: 28px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.get-started-btn:hover {
  background-color: #5B31A5;
}

/* Responsive Design */

/* Tablet View */
@media (max-width: 768px) {
  .logo {
      width: 80px;
      top: 20px;
      left: 20px;
  }

  .robot-icon {
      width: 120px;
  }

  .description-text {
      font-size: 20px;
      padding: 0 15px;
  }

  .how-it-works {
      font-size: 20px;
  }

  .grid-container {
      flex-direction: column;
      gap: 15px;
  }

  .grid-item {
      width: 90%;
      padding: 15px;
  }

  .get-started-btn {
      padding: 10px 20px;
      font-size: 16px;
  }
}

/* Mobile View */
@media (max-width: 480px) {
  .logo {
      width: 60px;
      top: 15px;
      left: 15px;
  }

  .robot-icon {
      width: 100px;
  }

  .description-text {
      font-size: 18px;
      padding: 0 10px;
  }

  .how-it-works {
      font-size: 18px;
  }

  .grid-item {
      width: 95%;
      padding: 15px;
  }

  .get-started-btn {
      padding: 8px 16px;
      font-size: 16px;
      border-radius: 24px;
  }
}

/* Chat Page Styles */
.chat-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f8f9fa;
}

.chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 1rem 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

.header-left {
  display: flex;
  align-items: center;
  gap: 12px;
  height: 32px;
}

.header-robot-icon {
  width: 40px;
  height: 32px;
  display: flex;
  align-items: center;
  position: relative;
  top: -10px;
}

.header-robot-icon .robot-icon {
  width: 32px;
  height: 32px;
  object-fit: contain;
  animation: floatAnimation 3s ease-in-out infinite;
}

.chat-logo {
  height: 32px;
  object-fit: contain;
}

.chat-main-container {
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  padding: 0 2rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  height: calc(100vh - 64px);
  margin-top: 80px;
}

.chat-container {
  flex: 1;
  overflow-y: auto;
  padding: 2rem 0;
  margin-bottom: 100px;
}

.chat-input-container {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 1000px;
  background: #f8f9fa;
  padding: 1rem 2rem;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.05);
  z-index: 10;
}

.input-wrapper {
  position: relative;
  background: white;
  border-radius: 12px;
  border: 1px solid #e0e0e0;
  padding: 12px;
  max-width: 100%;
}

.chat-input {
  width: 100%;
  min-height: 60px;
  padding: 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  resize: none;
  font-size: 1rem;
  margin-bottom: 1rem;
  transition: border-color 0.2s ease;
}

.chat-input:focus {
  outline: none;
  border-color: #7541D5;
}

.send-button {
  background: #7541D5;
  color: white;
  border: none;
  padding: 0.75rem 2rem;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.send-button:hover:not(:disabled) {
  background: #5B31A5;
}

.send-button:disabled {
  background: #a990e0;
  cursor: not-allowed;
}

.typing-indicator {
  padding: 1rem;
  color: #666;
  font-style: italic;
}

/* Responsive Design */
@media (max-width: 768px) {
  .chat-main-container {
      padding: 0 1rem;
  }

  .chat-input-container {
      padding: 1rem;
  }

  .send-button {
      width: 100%;
  }

  .chat-header {
      padding: 1rem;
  }

  .header-robot-icon {
      width: 28px;
      height: 28px;
  }

  .chat-logo {
      height: 28px;
  }
}

/* Chat Messages Styling */
.message-wrapper {
display: flex;
align-items: flex-start;
margin-bottom: 1.5rem;
padding: 0 1rem;
}

/* Bot message styling */
.message-wrapper.assistant {
justify-content: flex-start;
}

.message-wrapper.assistant .message-content {
background-color: #f0f0f0;
color: #2E0E46;
border-radius: 0 12px 12px 12px;
margin-left: 8px;
}

/* User message styling */
.message-wrapper.user {
justify-content: flex-end;
}

.message-wrapper.user .message-content {
background-color: #7541D5;
color: white;
border-radius: 12px 0 12px 12px;
}

/* Common message content styling */
.message-content {
max-width: 70%;
padding: 12px 16px;
font-size: 15px;
line-height: 1.5;
box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

/* Avatar styling */
.message-avatar {
width: 32px;
height: 32px;
flex-shrink: 0;
}

.avatar-icon {
width: 100%;
height: 100%;
object-fit: contain;
}

/* Responsive adjustments */
@media (max-width: 768px) {
.message-content {
  max-width: 85%;
}

.message-avatar {
  width: 28px;
  height: 28px;
}
}

/* Update chat container padding */
.chat-container {
padding: 2rem 0;
overflow-y: auto;
}

/* Optional: Add hover effect */
.message-content:hover {
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #FFF;
  border-bottom-color: #7541D5;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.chat-header {
  background-color: white;
  padding: 1rem 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.chat-logo {
  height: 32px;
  object-fit: contain;
}

.bot-intro {
  text-align: center;
  padding: 2rem 1rem;
  background: white;
  border-bottom: 1px solid #e0e0e0;
}

.bot-avatar {
  margin: 0 auto;
  width: 64px;
  height: 64px;
  animation: floatAnimation 3s ease-in-out infinite;
}

.robot-icon {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.bot-title {
  font-size: 24px;
  font-weight: 600;
  color: #2E0E46;
  margin: 1rem 0 0.5rem;
}

.bot-subtitle {
  font-size: 16px;
  color: #666;
  margin-bottom: 1.5rem;
}

.divider {
  height: 1px;
  background: #e0e0e0;
  max-width: 800px;
  margin: 0 auto;
}

.input-wrapper {
  position: relative;
  background: white;
  border-radius: 12px;
  border: 1px solid #e0e0e0;
  padding: 12px;
}

.chat-input {
  width: 100%;
  border: none;
  resize: none;
  padding-right: 40px;
  font-size: 16px;
  max-height: 200px;
  background: transparent;
}

.chat-input:focus {
  outline: none;
}

.send-icon-button {
  position: absolute;
  right: 12px;
  bottom: 12px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #7541D5;
  color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.send-icon-button:hover:not(:disabled) {
  background: #5B31A5;
}

.send-icon-button:disabled {
  background: #a990e0;
  cursor: not-allowed;
}

@keyframes floatAnimation {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-4px);
  }
  100% {
    transform: translateY(0px);
  }
}

/* IntroPage specific styles */
.main-container .robot-icon {
  width: 120px;  /* Reduced from original size */
  height: 120px;
  margin: 2rem 0;
  animation: floatAnimation 3s ease-in-out infinite;
}

/* ChatPage specific styles */
.bot-avatar .robot-icon {
  width: 64px;
  height: 64px;
  object-fit: contain;
}

/* Keep the floating animation */
@keyframes floatAnimation {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-4px);
  }
  100% {
    transform: translateY(0px);
  }
}

.submit-button {
  background: #7541D5; /* Theme color */
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.submit-button:hover {
  background: #5B31A5; /* Darker shade for hover */
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 400px;
  width: 100%;
}

.modal-content h2 {
  margin-bottom: 1.5rem;
}

.modal-buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.confirm-button, .cancel-button {
  padding: 0.5rem 1.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
}

.confirm-button {
  background-color: #7541D5;
  color: white;
}

.cancel-button {
  background-color: #ccc;
  color: black;
}

.confirm-button:hover {
  background-color: #5B31A5;
}

.cancel-button:hover {
  background-color: #aaa;
}

.success-icon {
  color: #28a745;
  font-size: 2rem;
  margin-bottom: 1rem;
}

.modal-buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.confirm-button, .cancel-button {
  padding: 0.5rem 1.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
}

.confirm-button {
  background-color: #7541D5;
  color: white;
}

.cancel-button {
  background-color: #ccc;
  color: black;
}

.confirm-button:hover {
  background-color: #5B31A5;
}

.cancel-button:hover {
  background-color: #aaa;
}

/* Admin Login Styles */
.login-container {
background: radial-gradient(circle at top left, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), rgba(255, 255, 255, 0.6);
border: 2px solid #FFFFFF;
box-shadow: inset -1.75px -1.75px 87.5px rgba(255, 255, 255, 0.02), inset 1.75px 1.75px 1.4px rgba(255, 255, 255, 0.1);
backdrop-filter: blur(7.35px);
padding: 2rem;
border-radius: 18px;
width: 100%;
max-width: 400px;
margin: 0 auto;
z-index: 1;
}

.login-container h1 {
color: #2E0E46;
font-size: 24px;
font-weight: 600;
margin-bottom: 1.5rem;
text-align: center;
letter-spacing: -0.015em;
}

.input-group {
margin-bottom: 1.5rem;
}

.input-group input {
width: 100%;
padding: 0.75rem 1rem;
border: 1px solid #e0e0e0;
border-radius: 28px;
font-size: 16px;
transition: all 0.3s ease;
background: white;
}

.input-group input:focus {
outline: none;
border-color: #7541D5;
box-shadow: 0 0 0 2px rgba(117, 65, 213, 0.1);
}

.error-message {
color: #dc3545;
margin-bottom: 1rem;
text-align: center;
font-size: 14px;
font-weight: 500;
}

/* Admin Dashboard Styles */
.dashboard-container {
width: 100%;
max-width: 1200px;
margin: 2rem auto;
padding: 2rem;
background: radial-gradient(circle at top left, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), rgba(255, 255, 255, 0.6);
border: 2px solid #FFFFFF;
box-shadow: inset -1.75px -1.75px 87.5px rgba(255, 255, 255, 0.02), inset 1.75px 1.75px 1.4px rgba(255, 255, 255, 0.1);
backdrop-filter: blur(7.35px);
border-radius: 18px;
z-index: 1;
}

.dashboard-header {
display: flex;
justify-content: space-between;
align-items: center;
margin-bottom: 2rem;
padding-bottom: 1rem;
border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.dashboard-header h1 {
color: #2E0E46;
font-size: 24px;
font-weight: 600;
margin: 0;
letter-spacing: -0.015em;
}

.dashboard-content {
color: #2E0E46;
}

/* Admin Specific Button Styles */
.admin-btn {
background-color: #7541D5;
color: #FFFFFF;
padding: 12px 24px;
font-size: 16px;
font-weight: 600;
border: none;
border-radius: 28px;
cursor: pointer;
transition: background-color 0.3s;
}

.admin-btn:hover:not(:disabled) {
background-color: #5B31A5;
}

.admin-btn:disabled {
background-color: #a990e0;
cursor: not-allowed;
}

/* Admin Loader */
.admin-loader-container {
display: flex;
justify-content: center;
align-items: center;
min-height: 200px;
}

.admin-loader {
width: 48px;
height: 48px;
border: 5px solid rgba(117, 65, 213, 0.1);
border-bottom-color: #7541D5;
border-radius: 50%;
display: inline-block;
animation: rotation 1s linear infinite;
}

/* Responsive Styles for Admin Pages */
@media (max-width: 768px) {
.login-container,
.dashboard-container {
  margin: 1rem;
  padding: 1.5rem;
}

.dashboard-header {
  flex-direction: column;
  gap: 1rem;
  text-align: center;
}

.input-group input {
  padding: 0.6rem 1rem;
  font-size: 14px;
}

.admin-btn {
  padding: 10px 20px;
  font-size: 14px;
}
}

@media (max-width: 480px) {
.login-container,
.dashboard-container {
  margin: 0.5rem;
  padding: 1rem;
}

.dashboard-header h1 {
  font-size: 20px;
}

.admin-btn {
  padding: 8px 16px;
  font-size: 14px;
}
}

/* Dashboard Styles */
.dashboard-page {
min-height: 100vh;
background-color: #f8f9fa;
}

.dashboard-content {
max-width: 1200px;
margin: 80px auto 0;
padding: 20px;
}

.search-container {
margin-bottom: 20px;
}

.search-input {
width: 100%;
padding: 12px;
border: 1px solid #e0e0e0;
border-radius: 8px;
font-size: 14px;
}

.sessions-table {
background: white;
border-radius: 8px;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
overflow-x: auto;
}

.sessions-table table {
width: 100%;
border-collapse: collapse;
}

.sessions-table th,
.sessions-table td {
padding: 12px 16px;
text-align: left;
border-bottom: 1px solid #e0e0e0;
}

.sessions-table th {
background-color: #f8f9fa;
font-weight: 600;
color: #2E0E46;
}

.sessions-table tr:hover {
background-color: #f8f9fa;
}

.load-more {
text-align: center;
margin-top: 20px;
padding: 20px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
.dashboard-content {
  padding: 10px;
}

.sessions-table th,
.sessions-table td {
  padding: 8px;
  font-size: 14px;
}
}

/* View History Button */
.view-history-btn {
background: #7541D5;
color: white;
border: none;
padding: 6px 12px;
border-radius: 4px;
cursor: pointer;
font-size: 14px;
transition: background-color 0.2s;
}

.view-history-btn:hover {
background: #5B31A5;
}

/* Chat History Overlay */
.chat-history-overlay {
position: fixed;
top: 0;
right: 0;
bottom: 0;
width: 100%;
max-width: 600px;
background: rgba(0, 0, 0, 0.5);
z-index: 1000;
}

.chat-history-sidebar {
position: absolute;
right: 0;
top: 0;
bottom: 0;
width: 100%;
background: white;
box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
display: flex;
flex-direction: column;
}

.chat-history-header {
padding: 1rem;
border-bottom: 1px solid #e0e0e0;
display: flex;
justify-content: space-between;
align-items: center;
}

.chat-history-header h2 {
margin: 0;
color: #2E0E46;
}

.close-btn {
background: none;
border: none;
font-size: 24px;
cursor: pointer;
color: #666;
}

.chat-history-messages {
flex: 1;
overflow-y: auto;
padding: 1rem;
}

/* Loading More Button State */
.admin-btn:disabled {
background-color: #a990e0;
cursor: not-allowed;
}

.message-wrapper {
display: flex;
align-items: flex-start;
margin-bottom: 16px; /* Add space between messages */
}

.message-avatar {
margin-right: 12px; /* Add space between avatar and text */
}

.message-content {
max-width: 600px;
word-wrap: break-word;
}

.input-wrapper {
/* ... existing styles ... */
padding-bottom: 24px; /* Increase padding to accommodate counter */
}

.char-counter {
position: absolute;
bottom: 4px;
left: 12px;
font-size: 12px;
color: #666;
}

/* When approaching limit */
.char-counter.warning {
color: #f1c40f;
}

/* When at limit */
.char-counter.limit {
color: #e74c3c;
}


.end-chat-options {
display: flex;
gap: 16px;
justify-content: center;
padding: 20px;
}

.end-chat-btn {
background-color: #e74c3c !important;
}

.end-chat-btn:hover:not(:disabled) {
background-color: #c0392b !important;
}

.continue-chat-btn {
background-color: #7541D5 !important;
}

.continue-chat-btn:hover:not(:disabled) {
background-color: #5B31A5 !important;
}

.end-chat-options button {
min-width: 150px;
padding: 12px 24px;
border-radius: 28px;
font-weight: 600;
color: white;
border: none;
cursor: pointer;
transition: background-color 0.3s ease;
}

.end-chat-options button:disabled {
opacity: 0.7;
cursor: not-allowed;
}

@media screen and (max-width: 768px) {
.grid-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
}

.grid-item {
  width: 100%;
  max-width: 335px;  /* or your preferred max-width for mobile */
}
}

.quality-indicator {
display: flex;
align-items: center;
gap: 8px;
padding: 4px 8px;
border-radius: 12px;
font-weight: 500;
}

table td {
padding: 12px;
vertical-align: middle;
}

/* Optional: Add hover effect to the quality indicator */
.quality-indicator:hover {
opacity: 0.8;
transition: opacity 0.2s ease;
}

.chat-history-header {
display: flex;
align-items: center;
gap: 10px;
}

.view-report-btn {
padding: 6px 12px;
background-color: #1a73e8;
color: white;
border: none;
border-radius: 4px;
cursor: pointer;
}

.view-report-btn:disabled {
background-color: #ccc;
cursor: not-allowed;
}

.sticky-report-button {
position: sticky;
bottom: 0;
background-color: white;
padding: 15px;
border-top: 1px solid #eee;
width: 100%;
text-align: center;
}

.view-report-btn {
padding: 10px 20px;
background-color: #1a73e8;
color: white;
border: none;
border-radius: 4px;
cursor: pointer;
width: 90%;
}

.view-report-btn:disabled {
background-color: #ccc;
cursor: not-allowed;
}

.message-content ul {
  padding-left: 2rem;  /* Increase left padding for bullet points */
}

/* Numbered lists (ol) */
.message-content ol {
  padding-left: 2rem;
}

/* Dash/hyphen lists */
.message-content ul li {
  list-style-type: disc;  /* for bullets */
}

.message-content ul li[data-dash] {
  list-style-type: none;
  text-indent: -1em;
  padding-left: 1em;
}

.message-content ul li[data-dash]::before {
  content: "- ";
  margin-right: 0.5em;
}

.report-buttons-container {
  display: flex;
  gap: 10px;
  width: 100%;
  padding: 0 5%;
}

.report-buttons-container .view-report-btn {
  flex: 1;
  width: auto;
}

.sticky-report-button {
  position: sticky;
  bottom: 0;
  background-color: white;
  padding: 15px 0;
  border-top: 1px solid #eee;
  width: 100%;
}

.view-report-btn {
  padding: 10px 20px;
  background-color: #1a73e8;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.view-report-btn:hover:not(:disabled) {
  background-color: #1557b0;
}

.view-report-btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Add these new styles */

.open-summary-btn {
    background: #7541D5;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.open-summary-btn:hover {
    background: #5B31A5;
}

/* Update existing chat-header styles if needed */
.chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Mobile responsiveness */
@media (max-width: 768px) {
    .open-summary-btn {
        padding: 6px 12px;
        font-size: 13px;
    }
    
    .chat-header {
        padding: 0.75rem 1rem;
    }
}