.session-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
}

.session-modal-popup {
  background: white;
  border-radius: 16px;
  padding: 24px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
  animation: modalPop 0.3s ease-out;
  position: relative;
}

.session-modal-icon {
  width: 48px;
  height: 48px;
  background: #7541D5;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 16px;
}

.session-modal-icon i {
  color: white;
  font-size: 24px;
}

.session-modal-header {
  font-size: 20px;
  font-weight: 600;
  color: #2E0E46;
  text-align: center;
  margin-bottom: 12px;
}

.session-modal-message {
  font-size: 16px;
  color: #666;
  text-align: center;
  margin-bottom: 24px;
  line-height: 1.5;
}

.session-modal-buttons {
  display: flex;
  gap: 12px;
  justify-content: center;
}

.session-modal-button {
  padding: 12px 24px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.2s ease;
  border: none;
}

.session-modal-button.primary {
  background: #7541D5;
  color: white;
}

.session-modal-button.primary:hover {
  background: #5B31A5;
}

.session-modal-button.secondary {
  background: #f0f0f0;
  color: #333;
}

.session-modal-button.secondary:hover {
  background: #e0e0e0;
}

@keyframes modalPop {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Mobile responsiveness */
@media (max-width: 480px) {
  .session-modal-popup {
    padding: 20px;
  }

  .session-modal-buttons {
    flex-direction: column;
  }

  .session-modal-button {
    width: 100%;
  }
}

.session-modal-close {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: none;
  background: #f0f0f0;
  color: #666;
  font-size: 20px;
  line-height: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.session-modal-close:hover {
  background: #e0e0e0;
  color: #333;
}
 