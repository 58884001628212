.summary-side-view {
    position: fixed;
    right: 0;
    top: 0;
    width: 30%;
    height: 100vh;
    background: white;
    box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
    transform: translateX(100%);
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    display: flex;
    flex-direction: column;
    z-index: 1000;
}

.summary-side-view.open {
    transform: translateX(0);
}

.summary-header {
    padding: 20px;
    border-bottom: 1px solid #eee;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.summary-header h2 {
    color: #2E0E46;
    font-size: 24px;
    font-weight: 600;
    margin: 0;
}

.summary-close-button {
    background: none;
    border: none;
    color: #666;
    cursor: pointer;
    padding: 8px;
}

.summary-content {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
}

.summary-item {
    display: flex;
    gap: 16px;
    margin-bottom: 32px;
    align-items: flex-start;
    position: relative;
}

.summary-icon {
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    position: relative;
    z-index: 1;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.summary-icon img {
    width: 24px;
    height: 24px;
    object-fit: contain;
}

.summary-text {
    flex: 1;
    min-width: 0;
}

.summary-text h3 {
    color: #2E0E46;
    font-size: 16px;
    font-weight: 600;
    margin: 0 0 4px 0;
}

.summary-text p {
    color: #666;
    font-size: 14px;
    margin: 0;
    line-height: 1.8;
    background: none;
}

.summary-text p br {
    display: block;
    content: "";
    margin-top: 8px;
}

.formatted-text {
    white-space: normal;
    word-wrap: break-word;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.formatted-text .paragraph {
    margin-bottom: 1em;
}

.formatted-text .paragraph:last-child {
    margin-bottom: 0;
}

.summary-actions {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.summary-actions button {
    width: 100%;
    padding: 12px;
    border-radius: 8px;
    border: none;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.end-session {
    background: #7541D5;
    color: white;
}

.end-session:hover {
    background: #5B31A5;
}

.continue-chat {
    background: transparent;
    border: 2px solid #7541D5 !important;
    color: #7541D5;
}

.continue-chat:hover {
    background: rgba(117, 65, 213, 0.1);
}

.summary-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    z-index: 999;
}

.summary-overlay.open {
    opacity: 1;
    visibility: visible;
}

.summary-item:not(:last-child)::after {
    content: '';
    position: absolute;
    left: calc(26px);
    top: 32px;
    width: 2px;
    height: calc(100% - 8px);
    border-left: 2px dashed #7541D5;
    z-index: 0;
}

@media (max-width: 768px) {
    .summary-side-view {
        width: 100%;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .summary-side-view {
        width: 50%;
    }
} 