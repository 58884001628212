/* src/components/SideMenu.css */
.side-menu {
    position: fixed;
    right: 0;
    top: 0;
    width: 400px;
    height: 100vh;
    background: white;
    box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    transform: translateX(100%);
    transition: transform 0.3s ease;
    z-index: 1000;
}

.side-menu.open {
    transform: translateX(0);
}

.side-menu-header {
    padding: 1.5rem;
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.summary-title {
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 24px;
    font-weight: 600;
    color: #2E0E46;
    letter-spacing: -0.015em;
}

.summary-title .robot-icon {
    width: 32px;
    height: 32px;
}

.close-button {
    background: none;
    border: none;
    color: #666;
    cursor: pointer;
    padding: 8px;
}

.side-menu-content {
    flex: 1;
    overflow-y: auto;
    padding: 1.5rem;
}

.summary-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.summary-item {
    font-size: 16px;
    line-height: 1.5;
    color: #2E0E46;
    padding: 12px;
    background: #f8f9fa;
    border-radius: 8px;
    font-weight: 400;
}

.loading-text {
    color: #2E0E46;
    font-size: 16px;
    font-weight: 500;
    margin-top: 1rem;
}

.submit-close-button {
    margin: 1.5rem;
    padding: 12px;
    background: #7541D5;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.submit-close-button:hover:not(:disabled) {
    background: #5B31A5;
}

.submit-close-button:disabled {
    background: #a990e0;
    cursor: not-allowed;
}

@media (max-width: 768px) {
    .side-menu {
        width: 100%;
    }
}